import React from 'react';

function ShowView() {
  return (
    <div>
      Show
    </div>
  );
}
export default ShowView;
