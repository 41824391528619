import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Culture as CultureApi } from '../../api/common';
import '../../styles/culture.scss';
import Pagination from '../Public/Pagination';

interface ActivityItem {
  activityId: number,
  textContent?: string,
  textDate: string,
  textDesc: string,
  textId: number,
  textImg: string,
  textReference: number,
  textTitle: string,
  textType: number,
}

function LabActivity() {
  const [activityData, setActivityData] = useState<ActivityItem[]>([]);
  const [activityCount, setActivityCount] = useState<number>(0);

  useEffect(() => {
    const fetchActivity = async () => {
      setActivityData(await CultureApi.getActivity(0));
    };
    fetchActivity();

    const fetchActivityCount = async () => {
      setActivityCount(await CultureApi.getActivityCount());
    };
    fetchActivityCount();
  });

  const activityList = (
    <div className="act-list">
      {
        activityData.map((item) => (
          <Col md={6} key={item.activityId}>
            <a className="act-card" href={`/Detail?type=activity&id=${item.activityId}`} key={item.activityId}>
              <img className="act-img" src={item.textImg} alt={item.textTitle} />
              <div className="act-content">
                <h3 className="act-title">{item.textTitle}</h3>
                <p className="act-desc">{item.textDesc}</p>
                <span className="act-date">{item.textDate.substring(0, 10)}</span>
              </div>
            </a>
          </Col>
        ))
      }
    </div>
  );

  return (
    <div>
      {activityList}
      <Pagination count={activityCount} />
    </div>
  );
}

export default LabActivity;
