import React from 'react';

function Rules() {
  return (
    <div>
      Rules
    </div>
  );
}

export default Rules;
