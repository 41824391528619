import React from 'react';

interface link {
  label: string,
  href: string
}

function Footer() {
  const links: link[] = [{
    label: '提升政府治理能力国家大数据工程实验室共建单位',
    href: 'http://cic.tju.edu.cn/',
  }, {
    label: '天津市机器学习重点实验室',
    href: 'http://cic.tju.edu.cn/',
  }, {
    label: '天津市智能遥感数据处理技术企业重点实验室',
    href: 'http://cic.tju.edu.cn/',
  }, {
    label: '天津市认知计算与应用重点实验室',
    href: 'http://cic.tju.edu.cn/',
  }, {
    label: '天津市文化遗产保护与传承工程技术中心',
    href: 'http://cic.tju.edu.cn/',
  }, {
    label: '天津市网络大数据国际联合中心',
    href: 'http://cic.tju.edu.cn/',
  }, {
    label: '文物本体表面监测与分析研究国家文物局重点科研基地',
    href: 'http://cic.tju.edu.cn/',
  }, {
    label: '天津市公共安全大数据技术工程中心',
    href: 'http://cic.tju.edu.cn/',
  }, {
    label: '天津市智能公交车载装备技术工程中心',
    href: 'http://cic.tju.edu.cn/',
  }, {
    label: '天津市新媒体艺术协同创新中心',
    href: 'http://cic.tju.edu.cn/',
  }, {
    label: '中国传统村落与建筑遗产保护协同创新中心',
    href: 'http://cic.tju.edu.cn/',
  }];
  return (
    <footer>
      <div className="section-one">
        <div className="main-wrap relative">
          <h2>快速链接</h2>
          <ul className="link clearfix">
            {links.map((item) => (
              <li key={item.label}>
                <a
                  href={item.href}
                  title={item.label}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {item.label}
                </a>
              </li>
            ))}
          </ul>
          <b />
        </div>
      </div>

      <div className="section-two">
        <div className="main-wrap clearfix">
          <div className="left">
            {/* <span>电话：010-62783675</span> */}
            {/* <span>传真：010-62787365</span> */}
            <span>邮编：300350</span>
            {' '}
            <br />
            <span>地址：天津市津南区海河教育园区雅观路135号天津大学北洋园校区55教学楼</span>
            <span>
              电子邮箱：
              <a
                href="mailto:coic@tju.edu.cn"
              >
                coic@tju.edu.cn
              </a>
            </span>
          </div>
          <div className="right">版权所有©天津市先进网络技术与应用重点实验室</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
