import React, { useEffect, useState } from 'react';
import { Achievement as AchievementApi } from '../../api/common';
import Pagination from '../Public/Pagination';

interface Patent {
  patentContent: string,
  patentDate: string,
  patentId: number,
  patentNotes: string,
  patentTypical: number,
}

function PatentView() {
  const [patentData, setPatentData] = useState<Patent[]>([]);
  const [yearData, setYearData] = useState<number[]>([]);
  const [patentCount, setPatentCount] = useState(0);
  const typicalData = [{ typeId: 0, typeName: '全部专利' }, { typeId: 1, typeName: '代表专利' }];
  const [selectedType, setSelectedType] = useState(0);
  const [selectedYear, setSelectedYear] = useState(0);

  useEffect(() => {
    const fetchPatent = async () => {
      setPatentData(await AchievementApi.getPatent(0));
    };
    fetchPatent();

    const fetchPatentCount = async () => {
      setPatentCount(await AchievementApi.getPatentCount());
    };
    fetchPatentCount();

    const fetchYear = async () => {
      setYearData(await AchievementApi.getPatentYear());
    };
    fetchYear();
  }, []);

  const handleChangeType = async (type: any) => {
    setSelectedType(type.typeId);
    if (type.typeName === '全部专利') {
      setPatentData(await AchievementApi.getPatent(0));
      setPatentCount(await AchievementApi.getPatentCount());
    } else {
      setPatentData(await AchievementApi.getPatentByType(0));
      setPatentCount(await AchievementApi.getPatentCountByType());
    }
  };

  const handleChangeYear = async (year: number) => {
    setSelectedYear(year);
    setSelectedType(0);
    setPatentData(await AchievementApi.getPatentByYear({ offset: 0, year }));
    setPatentCount(await AchievementApi.getPatentCountByYear(year));
  };

  const handleChangePage = async (page: number) => {
    if (selectedType === 0) {
      if (selectedYear !== 0) {
        // 年分页
        // eslint-disable-next-line max-len
        setPatentData(await AchievementApi.getPatentByYear({ offset: (page - 1) * 10, year: selectedYear }));
        setPatentCount(await AchievementApi.getPatentCountByYear(selectedYear));
      } else {
        // 全部类型分页
        setPatentData(await AchievementApi.getPatent((page - 1) * 10));
        setPatentCount(await AchievementApi.getPatentCount());
      }
    } else {
      // 类型分页
      setPatentData(await AchievementApi.getPatentByType((page - 1) * 10));
      setPatentCount(await AchievementApi.getPatentCountByType());
    }
  };

  const paperList = (
    <div>
      <div className="list-l-wz">
        {
          patentData.map((item) => (
            // eslint-disable-next-line
            <a key={item.patentId}>
              <div className="list-l-bg" />
              <h3>
                {item.patentTypical
                  ? <span style={{ color: '#004EB4' }}> [代表专利] </span> : null}
                {item.patentContent}
              </h3>
              <div className="list-l-time">
                <span>
                  {item.patentDate}
                </span>
              </div>
            </a>
          ))
        }
      </div>
    </div>
  );

  return (
    <div>
      <div className="news-filter">
        <div className="filters">
          <div className="filter-title">Topic</div>
          {
            typicalData.map((item) => (
              // eslint-disable-next-line
              <div
                key={item.typeId}
                className={selectedType === item.typeId ? 'filter-ins active' : 'filter-ins'}
                onClick={() => {
                  handleChangeType(item);
                }}
              >
                {item.typeName}
              </div>
            ))
          }
        </div>
        <div className="filters">
          <div className="filter-title">By Year</div>
          {
            yearData.map((item) => (
              // eslint-disable-next-line
              <div
                key={item}
                className={selectedYear === item ? 'filter-ins active' : 'filter-ins'}
                onClick={() => {
                  handleChangeYear(item);
                }}
              >
                {item}
              </div>
            ))
          }
        </div>
      </div>
      {
        paperList
      }
      <Pagination count={patentCount} onChangePage={handleChangePage} />
    </div>
  );
}

export default PatentView;
