import React from 'react';
import {
  createBrowserRouter,
  RouterProvider,
  Link,
} from 'react-router-dom';

import Home from './components/Home';
import Lab, {
  loader as labLoader,
} from './components/LabOverview/Lab';
import News from './components/News/News';
import Direction, {
  loader as direcitonLoader,
} from './components/Direction/Direction';
import Project from './components/Project/Project';
import Award from './components/Achievement/Award';
import Paper from './components/Achievement/Paper';
import Book from './components/Achievement/Book';
import Patent from './components/Achievement/Patent';
import Software from './components/Achievement/Software';
import Show from './components/Achievement/Show';
import Talent, {
  loader as talentLoader,
} from './components/Talent/Talent';
import Rules from './components/File/Rules';
import Office from './components/File/Office';
import LabHome from './components/Culture/LabHome';
import LabActivity from './components/Culture/LabActivity';
import LabShow from './components/Culture/LabShow';
import Detail from './components/Detail/Detail';
import NotFound from './components/404';

import Layout from './components/Layout';
import AppLayout from './components/AppLayout';

import ErrorPage from './error-page';

import './styles/index.scss';
import './styles/second.scss';

import navs from './nav';
import { Nav } from './interface';

function getNav(path: string) {
  return navs.find((element) => element.path === path) as Nav;
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <AppLayout />,
    errorElement: <ErrorPage />,
    handle: {
      crumb: () => <Link to="/">首页</Link>,
    },
    children: [
      { index: true, element: <Home /> },
      {
        path: 'overview',
        element: <Layout nav={getNav('/overview')} />,
        handle: {
          crumb: () => <Link to="/overview/info">实验室概况</Link>,
        },
        children: [
          {
            path: ':infoType',
            element: <Lab />,
            loader: labLoader,
            errorElement: <ErrorPage />,
            handle: {
              crumb: (data: any) => <span>{data?.label}</span>,
            },
          },
        ],
      },
      {
        path: 'news',
        element: <Layout nav={getNav('/news')} />,
        handle: {
          crumb: () => <span>新闻动态</span>,
        },
        children: [
          { index: true, element: <News /> },
        ],
      },
      {
        path: 'direction',
        element: <Layout nav={getNav('/direction')} />,
        handle: {
          crumb: () => <Link to="/direction/3">研究方向</Link>,
        },
        children: [
          {
            path: ':directionId',
            element: <Direction />,
            loader: direcitonLoader,
            errorElement: <ErrorPage />,
            handle: {
              crumb: (data: any) => <span>{data?.label}</span>,
            },
          },
        ],
      },
      {
        path: 'project',
        element: <Layout nav={getNav('/project')} />,
        handle: {
          crumb: () => <span>科研项目</span>,
        },
        children: [
          { index: true, element: <Project /> },
        ],
      },
      {
        path: 'achievement',
        element: <Layout nav={getNav('/achievement')} />,
        handle: {
          crumb: () => <Link to="/achievement/award">科研成果</Link>,
        },
        children: [
          {
            path: 'award',
            element: <Award />,
            handle: {
              crumb: () => <span>奖项</span>,
            },
          },
          {
            path: 'paper',
            element: <Paper />,
            handle: {
              crumb: () => <span>学术论文</span>,
            },
          },
          {
            path: 'book',
            element: <Book />,
            handle: {
              crumb: () => <span>专著</span>,
            },
          },
          {
            path: 'patent',
            element: <Patent />,
            handle: {
              crumb: () => <span>专利</span>,
            },
          },
          {
            path: 'software',
            element: <Software />,
            handle: {
              crumb: () => <span>软件著作权</span>,
            },
          },
          {
            path: 'show',
            element: <Show />,
            handle: {
              crumb: () => <span>成果展示</span>,
            },
          },
        ],
      },
      {
        path: 'talent',
        element: <Layout nav={getNav('/talent')} />,
        handle: {
          crumb: () => <Link to="/talent/1">人才培养</Link>,
        },
        children: [
          {
            path: ':talentId',
            element: <Talent />,
            loader: talentLoader,
            errorElement: <ErrorPage />,
            handle: {
              crumb: (data: any) => <span>{data?.label}</span>,
            },
          },
        ],
      },
      {
        path: 'file',
        element: <Layout nav={getNav('/file')} />,
        handle: {
          crumb: () => <Link to="/file/rules">资料下载</Link>,
        },
        children: [
          {
            path: 'rules',
            element: <Rules />,
            handle: {
              crumb: () => <span>规章制度</span>,
            },
          },
          {
            path: 'office',
            element: <Office />,
            handle: {
              crumb: () => <span>文档表格</span>,
            },
          },
        ],
      },
      {
        path: 'culture',
        element: <Layout nav={getNav('/culture')} />,
        handle: {
          crumb: () => <Link to="/culture/home">实验室文化</Link>,
        },
        children: [
          {
            path: 'home',
            element: <LabHome />,
            handle: {
              crumb: () => <span>实验室之家</span>,
            },
          },
          {
            path: 'activity',
            element: <LabActivity />,
            handle: {
              crumb: () => <span>团建活动</span>,
            },
          },
          {
            path: 'show',
            element: <LabShow />,
            handle: {
              crumb: () => <span>实验室风采</span>,
            },
          },
        ],
      },
      {
        path: 'Detail',
        element: <Detail />,
      },
    ],
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

function App() {
  return (
    <RouterProvider router={router} />
  );
}

export default App;
