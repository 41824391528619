import React, { useState, useEffect } from 'react';
import { News as NewsApi, Culture as CultureApi } from '../../api/common';
import '../../styles/news.scss';

// interface DetailType {
//   newDate: string,
//   textContent: string,
//   textImg: string,
//   textTitle: string
// }

function getQueryString(name: string) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`, 'i');
  const r = window.location.search.substr(1).match(reg);
  if (r != null) {
    return unescape(r[2]);
  }
  // return null;
  return 'error';
}

function Detail() {
  const [newData, setNewsData] = useState([]);
  const [activityData, setActivityData] = useState([]);

  const detailId = getQueryString('id');
  const detailType = getQueryString('type');

  useEffect(() => {
    // 获取新闻
    const fetchNewsData = async () => {
      setNewsData(await NewsApi.getDetail(detailId));
    };

    const fetchActivityData = async () => {
      setActivityData(await CultureApi.getActivityDetail(detailId));
    };
    if (!detailId || !detailType) {
      window.location.href = '/404';
    } else if (detailType === 'news') {
      fetchNewsData();
    } else if (detailType === 'activity') {
      fetchActivityData();
    }
  }, []);

  function switchType(Type: string) {
    switch (Type) {
      case 'news':
        return newData;
      case 'activity':
        return activityData;
      default:
        return '';
    }
  }

  const news = () => {
    const data: any = switchType(detailType);
    if (newData) {
      return (
        <div className="detail-body">
          <div className="detail-title">
            {data.textTitle}
            <span className="detail-date">
              {data.newDate}
            </span>
          </div>
          <div className="div-line" />
          <div className="detail-content" dangerouslySetInnerHTML={{ __html: data.textContent as string }} />
        </div>
      );
    }
    return null;
  };

  return (
    <div className="detail-page">
      {news()}
    </div>
  );
}

export default Detail;
