import React, { useEffect, useState } from 'react';
import { Col } from 'react-bootstrap';
import { Download as DownloadApi } from '../../api/common';
import Icon from './Icon';
import '../../styles/download.scss';

interface OfficeItem {
  fileId: number,
  fileName: string,
  fileSize: number,
  fileType: number,
  fileUpdatetime: string,
  fileUrl: string,
}

function Office() {
  const [officeData, setData] = useState([]);
  useEffect(() => {
    // 获取文档表格
    const fetchData = async () => {
      setData(await DownloadApi.getOffice());
    };
    fetchData();
  }, []);

  const fileSize = (size: number) => {
    const fileSizeByte = size;
    let fileSizeMsg = '';
    if (fileSizeByte < 1048576) fileSizeMsg = `${(fileSizeByte / 1024).toFixed(2)}KB`;
    else if (fileSizeByte === 1048576) fileSizeMsg = '1MB';
    else if (fileSizeByte > 1048576 && fileSizeByte < 1073741824) fileSizeMsg = `${(fileSizeByte / (1024 * 1024)).toFixed(2)}MB`;
    else if (fileSizeByte > 1048576 && fileSizeByte === 1073741824) fileSizeMsg = '1GB';
    else if (fileSizeByte > 1073741824 && fileSizeByte < 1099511627776) fileSizeMsg = `${(fileSizeByte / (1024 * 1024 * 1024)).toFixed(2)}GB`;
    else fileSizeMsg = '文件超过1TB';
    return fileSizeMsg;
  };

  const identifyFileType = (fileName: string) => {
    const typeName = fileName.split('.')[fileName.split('.').length - 1];
    let ico = Icon.any;
    const fdoc = ['doc', 'docx'];
    const fexcel = ['xls', 'xlsx'];
    const fimg = ['png', 'jpg', 'jpeg', 'gif', 'svg', 'webp'];
    const fmusic = ['mp3', 'wav', 'wma', 'aac'];
    const ftxt = ['txt'];
    const fzip = ['zip', '7z', 'rar'];
    const fppt = ['ppt', 'pptx'];
    const fpdf = ['pdf'];
    if (fdoc.includes(typeName)) {
      ico = Icon.doc;
    } else if (fexcel.includes(typeName)) {
      ico = Icon.xls;
    } else if (fimg.includes(typeName)) {
      ico = Icon.img;
    } else if (fmusic.includes(typeName)) {
      ico = Icon.music;
    } else if (ftxt.includes(typeName)) {
      ico = Icon.txt;
    } else if (fzip.includes(typeName)) {
      ico = Icon.zip;
    } else if (fpdf.includes(typeName)) {
      ico = Icon.pdf;
    } else if (fppt.includes(typeName)) {
      ico = Icon.ppt;
    }
    return ico;
  };

  const office = () => {
    const item: JSX.Element[] = [];
    officeData.forEach((data: OfficeItem) => {
      item.push(
        <Col xs={6} md={3} key={data.fileId}>
          <div className="office-card">
            <img src={identifyFileType(data.fileName)} alt={data.fileName} />
            <div className="office-body">
              <p className="office-title">{data.fileName}</p>
              <div>
                <span className="office-size">{`文件大小：${fileSize(data.fileSize)}`}</span>
                <button type="button" className="office-button" onClick={() => { window.open(data.fileUrl); }}>下载</button>
              </div>
            </div>
          </div>
        </Col>,
      );
    });
    return item;
  };

  return (
    <div>
      {office()}
    </div>
  );
}

export default Office;
