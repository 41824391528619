import React from 'react';
import {
  useLoaderData,
} from 'react-router-dom';
import showHtml from '../../utils/util';
import { Direction as DirectionApi } from '../../api/common';

interface DirectionResponse {
  introId: number,
  introName: string,
  introContent: string,
  introEditTime: string
}

interface DirectionMap {
  3: string
  4: string,
  17: string,
}

export async function loader({ params }: any) {
  const { directionId } = params;
  const directionMap = {
    3: '云计算',
    4: '智慧车联网',
    17: '智慧海洋',
  };
  if (!Object.keys(directionMap).includes(directionId)) {
    throw new Error('route params error');
  }
  const direction: DirectionResponse = await DirectionApi.getDirection(parseInt(directionId, 10));
  const label = directionMap[directionId as keyof DirectionMap];
  return { direction, label };
}

function Direction() {
  const { direction } = useLoaderData() as any;
  return (
    <div>
      {
        direction ? (
          <div className="main-wrap list-lr">
            <div className="list-l">
              <div className="twindex">
                {showHtml(direction.textContent)}
              </div>
            </div>
          </div>
        ) : '数据加载中'
      }
    </div>
  );
}

export default Direction;
