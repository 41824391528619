import React, { useEffect, useState } from 'react';
import Pagination from '../Public/Pagination';
import { Project as ProjectApi } from '../../api/common';

interface Project {
  projectId: number,
  projectSource: string,
  projectName: string,
  projectAmount: number,
  projectStartDate: string,
  projectEndDate: string,
}

function ProjectView() {
  const [projectData, setProjectData] = useState<Project[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [projectCount, setProjectCount] = useState(0);
  const offset: number = 0;
  useEffect(() => {
    // 获取项目列表
    const fetchData = async () => {
      setProjectData(await ProjectApi.getProject(0));
      setProjectCount(await ProjectApi.getProjectCount());
    };
    fetchData();
  }, [offset]);

  const handleChangePage = async (page:number) => {
    setProjectData(await ProjectApi.getProject((page - 1) * 10));
  };

  return (
    <div>
      <div className="list-l-wz">
        {
          projectData.map((item) => (
              // eslint-disable-next-line
              <a key={item.projectId}>
                <div className="list-l-bg" />
                <h3>{item.projectName}</h3>
                <p>
                  {item.projectAmount}
                  {' '}
                  万元
                </p>
                <p>{item.projectSource}</p>
                <div className="list-l-time">
                  <span>
                    {item.projectStartDate}
                    {' '}
                    -
                    {' '}
                    {item.projectEndDate}
                  </span>
                </div>
              </a>
          ))
        }
      </div>
      <Pagination count={projectCount} onChangePage={handleChangePage} />
    </div>
  );
}

export default ProjectView;
