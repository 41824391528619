import React from 'react';
import {
  useLoaderData,
} from 'react-router-dom';
import showHtml from '../../utils/util';
import { Talent as TalentApi } from '../../api/common';

interface TalentMap {
  1: string
  2: string,
  3: string,
}

interface TalentResponse {
  talentId: number,
  talentName: string,
  textContent: string,
  textDate: string,
  textDesc: string,
  textId: number,
  textImg: string,
  textReference: number
  textTitle: string,
  textType: number
}

export async function loader({ params }: any) {
  const talentMap: TalentMap = {
    1: '招生信息',
    2: '培养计划',
    3: '课程资源',
  };
  const { talentId } = params;
  if (!Object.keys(talentMap).includes(talentId)) {
    throw new Error('route params error');
  }
  const talent: TalentResponse = await TalentApi.getTalent(parseInt(talentId, 10));
  const label = talentMap[talentId as keyof TalentMap];
  return { talent, label };
}

function Talent() {
  const { talent } = useLoaderData() as any;
  return (
    <div>
      <div>
        {
          talent && (
          <div className="main-wrap list-lr">
            <div className="list-l">
              <div className="twindex">
                {showHtml(talent.textContent)}
              </div>
            </div>
          </div>
          )
        }
      </div>
    </div>
  );
}

export default Talent;
