import React from 'react';
// import {
//   useLocation,
//   Link,
// } from 'react-router-dom';
import tanklab from '../assets/header/tanklab.png';
// import navs from '../nav';
// import { Nav } from '../interface';

// function CustomLink({
//   children, to, parent, ...props
// }: any) {
//   const { pathname } = useLocation();
//   let match = pathname === to.toString();
//   if (parent) {
//     match = pathname.startsWith(parent);
//   }
//   return (
//     <Link
//       className={match ? 'current' : 'none'}
//       to={to}
//       {...props}
//     >
//       {children}
//     </Link>
//   );
// }

function Header() {
  // const subNavMenu = (item: Nav) => {
  //   if (item.children) {
  //     const menu = item.children?.map((element) => (
  //       <li key={element.meta.label}>
  //         <Link
  //           to={element.path}
  //         >
  //           {element.meta.label}
  //         </Link>
  //       </li>
  //     ));
  //     return (
  //       <ul>
  //         <i><em /></i>
  //         {menu}
  //       </ul>
  //     );
  //   }
  //   return null;
  // };
  // const headerItems = navs.map((item: Nav) => {
  //   let { path } = item;
  //   let parent;
  //   if (item.children?.length) {
  //     path = item.children[0].path;
  //     parent = item.path;
  //   }
  //   return (
  //     <li key={item.path}>
  //       <CustomLink
  //         to={path}
  //         parent={parent}
  //       >
  //         {item.meta.label}
  //       </CustomLink>
  //       {subNavMenu(item)}
  //     </li>
  //   );
  // });

  return (
    <div>
      <header className="header clearfix">
        <div className="top-wraper relative clearfix">
          <a href="/" className="logo">
            <img src={tanklab} alt="tanklab" height={60} />
          </a>
        </div>
        {/* <nav className="nav-wrap">
          <section className="main-wrap">
            <ul id="nav">
              {headerItems}
            </ul>
          </section>
        </nav> */}
      </header>
    </div>
  );
}
export default Header;
