import React from 'react';
import {
  Link,
  Outlet,
  useMatches,
} from 'react-router-dom';

import { Nav } from '../interface';

interface LayoutProps {
  nav: Nav
}

function Layout(props: LayoutProps) {
  const matches = useMatches();
  const lastMatch = matches[matches.length - 1];
  const { pathname } = lastMatch;
  const { nav } = props;
  const SubNavs = nav.children?.map((item) => (
    <div key={item.path}>
      <Link
        to={item.path}
        className={item.path === pathname ? 'bg' : ''}
      >
        {item.meta.label}
      </Link>
    </div>
  ));
  const crumbs = matches
    .filter((match) => Boolean((match.handle as any)?.crumb))
    .map((match, index, arr) => {
      const crumb = (match.handle as any).crumb(match.data);
      if (index === arr.length - 1) {
        return (
          <span key={match.id}>
            {crumb}
          </span>
        );
      }
      return (
        <span key={match.id}>
          {crumb}
          <span> / </span>
        </span>
      );
    });
  return (
    <div>
      <nav className="sec-nav-wrap">
        <h3>{nav.meta.label}</h3>
        <div className="se-secondnav">
          {SubNavs}
        </div>
      </nav>
      <div className="main-wrap list-lr">
        <div className="list-l">
          <nav>
            {crumbs}
          </nav>
          <h2>
            {4}
            <b />
          </h2>
          <Outlet />
        </div>
      </div>
    </div>

  );
}
export default Layout;
