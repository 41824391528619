import React, { useState, useEffect } from 'react';
import { Culture as CultureApi } from '../../api/common';

interface Researcher {
    studentAcademicStart: string,
    studentGender: string,
    studentGraduationTime: string,
    studentHomepage: string,
    studentId: number,
    studentImg: string,
    studentInSchool: number, // 离校0 在校1
    studentJob: string,
    studentMotto: string,
    studentName: string,
    studentResearch: string,
    studentTutor: string,
    studentType: number// 硕士0 博士1
}

interface Tag {
    tagId: number,
    tagName: string
}

function LabHome() {
  const [AllStudentData, setAllStudentData] = useState<Researcher[]>([]);
  const [renderList, setRenderList] = useState<Researcher[]>([]);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const [selectedTag, setSelectedTag] = useState<number>(-1);
  const [yearData, setYearData] = useState<number[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [studentCount, setStudentCount] = useState(0);
  const allStudent = { tagId: -1, tagName: '全部学生' };
  const studentTypeData = [{
    tagId: 1, tagName: '博士研究生',
  },
  {
    tagId: 2, tagName: '硕士研究生',
  }, {
    tagId: 3, tagName: '优秀校友',
  }];

  useEffect(() => {
    const fetchAllStudent = async () => {
      setAllStudentData(await CultureApi.getAllStudents());
      setRenderList(await CultureApi.getAllStudents());
    };
    fetchAllStudent();

    const fetchYear = async () => {
      setYearData(await CultureApi.getStudentYear());
    };
    fetchYear();
  }, []);

  // eslint-disable-next-line
  const getHomePage = (item: Researcher) => {
    if (item.studentHomepage) {
      return (
        // eslint-disable-next-line
        <a href={item.studentHomepage} target="_blank"><i className="fa-address-card fas" /></a>
      );
    }
  };

  // eslint-disable-next-line
  const switchType = (item: Researcher) => {
    if (item.studentInSchool === 0) {
      return 3;
    } if (item.studentType === 1) {
      return 1;
    } if (item.studentType === 0) {
      return 2;
    }
  };

  // eslint-disable-next-line no-shadow
  const typeFilter = async (selectedTag: number, selectedYear: number) => {
    let data = AllStudentData;
    // console.log("tag:" + selectedTag + "&&year:" + selectedYear)
    if (selectedYear !== 0) {
      data = await CultureApi.getStudentByYear(selectedYear);
    } else {
      data = await CultureApi.getAllStudents();
    }
    // console.log(data)
    const List: Researcher[] = [];
    if (selectedTag === -1) {
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        List.push(item);
      });
    } else {
      // eslint-disable-next-line array-callback-return
      data.map((item) => {
        if (selectedTag === switchType(item)) {
          List.push(item);
        }
      });
    }
    // console.log(List);
    setRenderList(List);
    setStudentCount(List.length);
  };

  const handleChangeYear = async (year: number) => {
    setSelectedYear(year);
    setSelectedTag(-1);
    typeFilter(-1, year);
  };

  const handleChangeTag = async (tag: Tag) => {
    setSelectedTag(tag.tagId);
    typeFilter(tag.tagId, selectedYear);
  };

  const researcherList = (
    <ul className="lecturelist clearfix">
      {renderList.map((item) => (
        <li key={item.studentId} className="yjry_list">
          <a href={item.studentHomepage}>
            <img src={item.studentImg} alt={item.studentName} />
            <h3>{item.studentName}</h3>
            <span>{`研究方向:${item.studentResearch}`}</span>
          </a>
        </li>
      ))}
    </ul>
  );

  return (
    <div className="imglist">
      <div className="news-filter">
        <div className="filters">
          <div className="filter-title">入学年份</div>
          {
                        yearData.map((item) => (
                            // eslint-disable-next-line
                            <div
                              key={item}
                              className={selectedYear === item ? 'filter-ins active' : 'filter-ins'}
                              onClick={() => {
                                handleChangeYear(item);
                              }}
                            >
                              {item}
                            </div>
                        ))
                    }
        </div>
        <div className="filters">

          <div className="filter-title">学生类型</div>
          {/* eslint-disable-next-line */}
                    <div
                      key={allStudent.tagId}
                      className={selectedTag === allStudent.tagId ? 'filter-ins active' : 'filter-ins'}
                      onClick={() => {
                        handleChangeTag(allStudent);
                      }}
                    >
                      {allStudent.tagName}
                    </div>
          {
                        studentTypeData.map((item) => (
                            // eslint-disable-next-line
                            <div
                              key={item.tagId}
                              className={selectedTag === item.tagId ? 'filter-ins active' : 'filter-ins'}
                              onClick={() => {
                                handleChangeTag(item);
                              }}
                            >
                              {item.tagName}
                            </div>
                        ))
                    }
        </div>
      </div>
      {researcherList}
    </div>
  );
}

export default LabHome;
