// 将nav和router解耦，方便编写Header
export default [
  {
    path: '/',
    meta: { label: '首页' },
  },
  {
    path: '/overview',
    meta: { label: '实验室概况' },
    children: [
      {
        path: '/overview/info',
        meta: { label: '实验室简介' },
      },
      {
        path: '/overview/speech',
        meta: { label: '主任致辞' },
      },
      {
        path: '/overview/organization',
        meta: { label: '实验室机构' },
      },
      {
        path: '/overview/professor',
        meta: { label: '导师介绍' },
      },
    ],
  },
  {
    path: '/news',
    meta: { label: '新闻动态' },
  },
  {
    path: '/direction',
    meta: { label: '研究方向' },
    children: [
      {
        path: '/direction/3',
        meta: { label: '云计算' },
      },
      {
        path: '/direction/4',
        meta: { label: '智慧车联网' },
      },
      {
        path: '/direction/17',
        meta: { label: '智慧海洋' },
      },
    ],
  },
  {
    path: '/project',
    meta: { label: '科研项目' },
  },
  {
    path: '/achievement',
    meta: { label: '科研成果' },
    children: [
      {
        path: '/achievement/award',
        meta: { label: '奖项' },
      },
      {
        path: '/achievement/paper',
        meta: { label: '学术论文' },
      },
      {
        path: '/achievement/book',
        meta: { label: '专著' },
      },
      {
        path: '/achievement/patent',
        meta: { label: '专利' },
      },
      {
        path: '/achievement/software',
        meta: { label: '软件著作权' },
      },
      {
        path: '/achievement/show',
        meta: { label: '成果展示' },
      },
    ],
  },
  {
    path: '/talent',
    meta: { label: '人才培养' },
    children: [
      {
        path: '/talent/1',
        meta: { label: '招生信息' },
      },
      {
        path: '/talent/2',
        meta: { label: '培养计划' },
      },
      {
        path: '/talent/3',
        meta: { label: '课程资源' },
      },
    ],
  },
  {
    path: '/file',
    meta: { label: '资料下载' },
    children: [
      {
        path: '/file/rules',
        meta: { label: '规章制度' },
      },
      {
        path: '/file/office',
        meta: { label: '文档表格' },
      },
    ],
  },
  {
    path: '/culture',
    meta: { label: '实验室文化' },
    children: [
      {
        path: '/culture/home',
        meta: { label: '实验室之家' },
      },
      {
        path: '/culture/activity',
        meta: { label: '团建活动' },
      },
      {
        path: '/culture/show',
        meta: { label: '实验室风采' },
      },
    ],
  },
];
