import React from 'react';
import { Button } from 'react-bootstrap';
import '../styles/common.scss';

function notFound() {
  return (
    <div className="error-page">
      <h1>4 0 4</h1>
      <p>您访问的页面并不存在</p>
      <div>
        <Button onClick={() => { window.history.back(); }} className="default">返回</Button>
      </div>
      {' '}

    </div>
  );
}
export default notFound;
