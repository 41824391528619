import React from 'react';
import {
  useLoaderData,
} from 'react-router-dom';
import showHtml from '../../utils/util';
import { Lab as LabApi } from '../../api/common';

interface LabIntroResponse {
  introId: number,
  introName: string,
  introContent: string,
  introEditTime: string
}

interface LabIntroMap {
  info: string
  speech: string,
  organization: string,
  professor: string,
}

export async function loader({ params }: any) {
  const { infoType } = params;
  const labIntroMap: LabIntroMap = {
    info: '实验室简介',
    speech: '主任致辞',
    organization: '实验室机构',
    professor: '导师介绍',
  };
  if (!Object.keys(labIntroMap).includes(infoType)) {
    throw new Error('route params error');
  }
  const intro: LabIntroResponse = await LabApi.getLabIntro(infoType);
  const label = labIntroMap[intro.introName as keyof LabIntroMap];
  return { intro, label };
}

function Lab() {
  const { intro } = useLoaderData() as any;
  return (
    <div>
      {
        intro && (
          <div className="main-wrap list-lr">
            <div className="list-l">
              <div className="twindex">
                {showHtml(intro.introContent)}
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default Lab;
