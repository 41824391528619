import React, { useEffect, useState } from 'react';
import { Achievement as AchievementApi } from '../../api/common';
import Pagination from '../Public/Pagination';

interface Book {
  bookContent:string,
  bookDate:string,
  bookId:number,
  bookNotes:string,
  bookTypical:number
}
function BookView() {
  const [bookData, setBookData] = useState<Book[]>([]);
  const [bookCount, setBookCount] = useState(0);

  useEffect(() => {
    const fetchBook = async () => {
      setBookData(await AchievementApi.getBook(0));
    };
    fetchBook();

    const fetchBookCount = async () => {
      setBookCount(await AchievementApi.getBookCount());
    };
    fetchBookCount();
  }, []);

  const handleChangePage = async (page:number) => {
    setBookData(await AchievementApi.getBook((page - 1) * 10));
  };

  const bookList = (
    <div>
      <div className="list-l-wz">
        {
          bookData.map((item) => (
            // eslint-disable-next-line
            <a key={item.bookId}>
              <div className="list-l-bg" />
              <h3>
                {item.bookTypical && <span style={{ color: '#004EB4' }}> [代表著作] </span>}
                {item.bookContent}
                {item.bookNotes && (
                <span style={{ color: '#004EB4' }}>
                  (
                    {item.bookNotes}
                  )
                </span>
                )}
              </h3>
              <div className="list-l-time">
                <span>
                  {item.bookDate}
                </span>
              </div>
            </a>
          ))
        }
      </div>
    </div>
  );

  return (
    <div>
      {
        bookList
      }
      <Pagination count={bookCount} onChangePage={handleChangePage} />
    </div>
  );
}

export default BookView;
