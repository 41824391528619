import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import { Culture as CultureApi } from '../../api/common';
import '../../styles/culture.scss';

interface ShowItem {
  photoDate: string,
  photoDesc: string,
  photoId: number,
  photoTitle: string,
  photoUrl: string,
}

function LabShow() {
  const [showData, setShowData] = useState<ShowItem[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [showCount, setShowCount] = useState<number>(0);

  useEffect(() => {
    const fetchShow = async () => {
      setShowData(await CultureApi.getPhoto(0));
    };
    fetchShow();
    const fetchShowCount = async () => {
      setShowCount(await CultureApi.getPhotoCount());
    };
    fetchShowCount();
  });

  const showList = (
    <div className="show-list">
      {
        showData.map((item) => (
          <Col md={6} key={item.photoId}>
            <div className="show-card" key={item.photoId}>
              <img className="show-img" src={item.photoUrl} alt={item.photoTitle} />
              <div className="show-content">
                <span className="show-date">{item.photoDate.substring(0, 10)}</span>
                <h3 className="show-title">{item.photoTitle}</h3>
                <p className="show-desc">{item.photoDesc}</p>
              </div>
            </div>
          </Col>
        ))
      }
    </div>
  );

  return (
    <div>
      {showList}
    </div>
  );
}

export default LabShow;
