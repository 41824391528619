import doc from '../../assets/files/doc.png';
import any from '../../assets/files/any.png';
import xls from '../../assets/files/excel.png';
import img from '../../assets/files/img.png';
import music from '../../assets/files/music.png';
import pdf from '../../assets/files/pdf.png';
import ppt from '../../assets/files/ppt.png';
import txt from '../../assets/files/txt.png';
import zip from '../../assets/files/zip.png';

export default {
  doc, any, xls, img, music, pdf, ppt, txt, zip,
};
