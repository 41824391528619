import React, { useEffect, useState } from 'react';

function Pagination(props: any) {
  const { count, onChangePage } = props;
  const [selectedPage, setSelectedPage] = useState(1);

  useEffect(() => {
    setSelectedPage(1);
  }, [count]);

  const handleChangePage = (page: number) => {
    setSelectedPage(page);
    onChangePage(page);
  };

  const handleClickPrev = () => {
    if (selectedPage > 1) {
      setSelectedPage(selectedPage - 1);
      onChangePage(selectedPage - 1);
    }
  };

  const handleClickNext = () => {
    if (selectedPage < (count / 10)) {
      setSelectedPage(selectedPage + 1);
      onChangePage(selectedPage + 1);
    }
  };

  const paginationItem = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= (count / 10) + 1; i++) {
    if (i === selectedPage) {
      paginationItem.push(
        // eslint-disable-next-line
        <li key={i} className="page-item active" onClick={() => handleChangePage(i)}>
          <span className="page-link">
            {i}
            <span className="sr-only">(current)</span>
          </span>
        </li>,
      );
    } else {
      // eslint-disable-next-line
      paginationItem.push(<li key={i} className="page-item" onClick={() => handleChangePage(i)}><a className="page-link" role="button">{i}</a></li>);
    }
  }
  return (
    <ul className="pagination" style={{ margin: '20px' }}>
      {/* eslint-disable-next-line */}
      <li className="page-item" onClick={handleClickPrev}>
        {/* eslint-disable-next-line */}
        <a className="page-link" role="button">
          <span aria-hidden="true">‹</span>
          <span
            className="sr-only"
          >
            Previous
          </span>
        </a>
      </li>
      {
        paginationItem
      }
      {/* eslint-disable-next-line */}
      <li className="page-item" onClick={handleClickNext}>
        {/* eslint-disable-next-line */}
        <a className="page-link" role="button">
          <span aria-hidden="true">&gt;</span>
          <span
            className="sr-only"
          >
            Next
          </span>
        </a>
      </li>
    </ul>
  );
}

export default Pagination;
