import React, { useEffect, useState } from 'react';
import Pagination from '../Public/Pagination';
import { Achievement as AchievementApi, News as NewsApi } from '../../api/common';

interface Award {
  awardId: number,
  awardContent: string,
  awardDate: string,
  awardNote: string,
  awardType: number,
  awardTypical: number,
}

function AwardView() {
  const [awardData, setAwardData] = useState<Award[]>([]);
  const [selectedType, setSelectedType] = useState(0);
  const [awardCount, setAwardCount] = useState(0);
  const typeData = [{ typeId: 0, typeName: '科技奖' }, { typeId: 1, typeName: '论文奖' }, { typeId: -1, typeName: '全部奖项' }];

  useEffect(() => {
    const fetchAward = async () => {
      setAwardData(await AchievementApi.getAward(0));
    };
    fetchAward();

    const fetchAwardCount = async () => {
      setAwardCount(await AchievementApi.getAwardCount());
    };
    fetchAwardCount();
  }, []);

  const handleChangeType = async (type: any) => {
    setSelectedType(type.typeId);
    if (type.typeName === '全部奖项') {
      setAwardData(await AchievementApi.getAward(0));
      setAwardCount(await AchievementApi.getAwardCount());
    } else {
      setAwardData(await AchievementApi.getAwardByType({ offset: 0, type: type.typeId }));
      setAwardCount(await AchievementApi.getAwardCountByType(type.typeId));
    }
  };

  const handleChangePage = async (page: number) => {
    if (selectedType === -1) {
      setAwardData(await AchievementApi.getAward((page - 1) * 10));
      setAwardCount(await AchievementApi.getAwardCount());
    } else {
      // eslint-disable-next-line max-len
      setAwardData(await AchievementApi.getAwardByType({ offset: (page - 1) * 10, type: selectedType }));
      setAwardCount(await AchievementApi.getAwardCountByType(selectedType));
    }
    setAwardData(await NewsApi.getNews(10 * (page - 1)));
  };

  const awardList = (
    <div>
      <div className="list-l-wz">
        {
          awardData.map((item) => (
            // eslint-disable-next-line
            <a key={item.awardId}>
              <div className="list-l-bg" />
              <h3>
                {item.awardTypical
                  ? <span style={{ color: '#004EB4' }}> [代表奖项] </span> : null}
                {item.awardContent}
              </h3>
              <p>{(typeData.filter((_item) => _item.typeId === item.awardType))[0].typeName}</p>
              <div className="list-l-time">
                <span>
                  {item.awardDate}
                </span>
              </div>
            </a>
          ))
        }
      </div>
    </div>
  );
  return (
    <div>
      <div className="news-filter">
        <div className="filters">
          <div className="filter-title">Topic</div>
          {
            typeData.map((item) => (
              // eslint-disable-next-line
              <div
                key={item.typeId}
                className={selectedType === item.typeId ? 'filter-ins active' : 'filter-ins'}
                onClick={() => {
                  handleChangeType(item);
                }}
              >
                {item.typeName}
              </div>
            ))
          }
        </div>
      </div>
      {
        awardList
      }
      <Pagination count={awardCount} onChangePage={handleChangePage} />
    </div>
  );
}
export default AwardView;
