import React, { useEffect, useState } from 'react';
import { Achievement as AchievementApi } from '../../api/common';
import Pagination from '../Public/Pagination';

interface Software {
  softwareContent: string
  softwareDate: string
  softwareId: number
  softwareNotes: string
  softwareTypical: number
}
function SoftwareView() {
  const [softwareData, setSoftwareData] = useState<Software[]>([]);
  const [softwareCount, setSoftwareCount] = useState(0);

  useEffect(() => {
    const fetchSoftware = async () => {
      setSoftwareData(await AchievementApi.getSoftware(0));
    };
    fetchSoftware();

    const fetchSoftwareCount = async () => {
      setSoftwareCount(await AchievementApi.getSoftwareCount());
    };
    fetchSoftwareCount();
  }, []);

  const handleChangePage = async (page:number) => {
    setSoftwareData(await AchievementApi.getSoftware((page - 1) * 10));
  };

  const softwareList = (
    <div>
      <div className="list-l-wz">
        {
          softwareData.map((item) => (
            // eslint-disable-next-line
            <a key={item.softwareId}>
              <div className="list-l-bg" />
              <h3>
                {item.softwareTypical && <span style={{ color: '#004EB4' }}> [代表著作] </span>}
                {item.softwareContent}
              </h3>
              <div className="list-l-time">
                <span>
                  {item.softwareDate}
                </span>
              </div>
            </a>
          ))
        }
      </div>
    </div>
  );
  return (
    <div>
      {
        softwareList
      }
      <Pagination count={softwareCount} onChangePage={handleChangePage} />
    </div>
  );
}

export default SoftwareView;
