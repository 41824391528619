import fetchApi from './callApi';

const GET_METHOD = {
  method: 'GET',
  mode: 'no-cors',
};

// 首页
const Home = {
  getNewsList: (num: number) => {
    const apiPath: string = `/new/getHomeList?num=${num}`;
    return fetchApi(apiPath, GET_METHOD);
  },
};

// 获取实验室概况
const Lab = {
  getLabIntro: async (column: string) => {
    const apiPath: string = `/intro/getLabIntro?introName=${column}`;
    return fetchApi(apiPath, GET_METHOD);
  },
};

// 获取研究方向
const Direction = {
  getDirection: async (directionId: number) => {
    const apiPath: string = `/researchdirection/getResearchDirectionById?directionId=${directionId}`;
    return fetchApi(apiPath, GET_METHOD);
  },
};

// 获取新闻
const News = {
  getNews: (offset: number) => {
    const apiPath: string = `/new/getNewListByffset?offset=${offset}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getTopic: () => {
    const apiPath: string = '/new/getTagsList';
    return fetchApi(apiPath, GET_METHOD);
  },
  getYear: () => {
    const apiPath: string = '/new/getYearList';
    return fetchApi(apiPath, GET_METHOD);
  },
  getNewsByTag: (params: any) => {
    const { offset, tagId } = params;
    const apiPath = `/new/getNewListByTagIdAndOffset?offset=${offset}&tagId=${tagId}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getNewsCountByTag: (tagId: number) => {
    const apiPath = `/new/getNewCountByTag?tagId=${tagId}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getNewsByYear: (params: any) => {
    const { offset, year } = params;
    const apiPath = `/new/getNewListByYearIdAndOffset?offset=${offset}&year=${year}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getNewsCountByYear: (year: number) => {
    const apiPath = `/new/getNewCountByYear?year=${year}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getDetail: (id: string) => {
    const apiPath = `/new/getNewById?newId=${id}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getNewsCount: () => {
    const apiPath = '/new/getNewNumber';
    return fetchApi(apiPath, GET_METHOD);
  },
};

// 获取科研项目
const Project = {
  getProject: (params: any) => {
    const apiPath = `/researchproject/getProjectByOffset?offset=${params}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getProjectCount: () => {
    const apiPath = '/researchproject/getProjectCount';
    return fetchApi(apiPath, GET_METHOD);
  },
};

// 获取资料下载
const Download = {
  getRules: () => {
    const apiPath = '/files/getByFileAndTypeAndOffset?offset=0&fileType=2';
    return fetchApi(apiPath, GET_METHOD);
  },
  getOffice: () => {
    const apiPath = '/files/getByFileAndTypeAndOffset?offset=0&fileType=3';
    return fetchApi(apiPath, GET_METHOD);
  },
};

// 获取科研成果
const Achievement = {
  getAward: (offset: number) => {
    const apiPath = `/researchachieve/getAwardByOffset?offset=${offset}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getAwardCount: () => {
    const apiPath = '/researchachieve/getAwardCount';
    return fetchApi(apiPath, GET_METHOD);
  },
  getAwardByType: (params: any) => {
    const { type, offset } = params;
    const apiPath = `/researchachieve/getAwardByTypeAndOffset?type=${type}&offset=${offset}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getAwardCountByType: (type: number) => {
    const apiPath = `/researchachieve/getAwardByTypeCount?type=${type}`;
    return fetchApi(apiPath, GET_METHOD);
  },

  getPaper: (offset: number) => {
    const aptPath = `/researchachieve/getPaperByOffset?offset=${offset}`;
    return fetchApi(aptPath, GET_METHOD);
  },
  getPaperCount: () => {
    const apiPath = '/researchachieve/getPaperCount';
    return fetchApi(apiPath, GET_METHOD);
  },
  getPaperYear: () => {
    const apiPath = '/researchachieve/getPaperYearList';
    return fetchApi(apiPath, GET_METHOD);
  },
  getPaperByType: (offset: number) => {
    const apiPath = `/researchachieve/getTypicalPaperByOffset?offset=${offset}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getPaperCountByType: () => {
    const apiPath = '/researchachieve/getTypicalPaperCount';
    return fetchApi(apiPath, GET_METHOD);
  },
  getPaperByYear: (params: any) => {
    const { year, offset } = params;
    const apiPath = `/researchachieve/getYearPaperByOffset?offset=${offset}&year=${year}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getPaperCountByYear: (year: number) => {
    const apiPath = `/researchachieve/getYearPaperCount?year=${year}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getBook: (offset: number) => {
    const apiPath = `/researchachieve/getBookByOffset?offset=${offset}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getBookCount: () => {
    const apiPath = '/researchachieve/getBookCount';
    return fetchApi(apiPath, GET_METHOD);
  },
  getPatent: (offset: number) => {
    const aptPath = `/researchachieve/getPatentByOffset?offset=${offset}`;
    return fetchApi(aptPath, GET_METHOD);
  },
  getPatentCount: () => {
    const apiPath = '/researchachieve/getPatentCount';
    return fetchApi(apiPath, GET_METHOD);
  },
  getPatentYear: () => {
    const apiPath = '/researchachieve/getPatentYearList';
    return fetchApi(apiPath, GET_METHOD);
  },
  getPatentByType: (offset: number) => {
    const apiPath = `/researchachieve/getTypicalPatentByOffset?offset=${offset}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getPatentCountByType: () => {
    const apiPath = '/researchachieve/getTypicalPatentCount';
    return fetchApi(apiPath, GET_METHOD);
  },
  getPatentByYear: (params: any) => {
    const { year, offset } = params;
    const apiPath = `/researchachieve/getYearPatentByOffset?offset=${offset}&year=${year}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getPatentCountByYear: (year: number) => {
    const apiPath = `/researchachieve/getYearPatentCount?year=${year}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getSoftware: (offset: number) => {
    const apiPath = `/researchachieve/getSoftwareByOffset?offset=${offset}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getSoftwareCount: () => {
    const apiPath = '/researchachieve/getSoftwareCount';
    return fetchApi(apiPath, GET_METHOD);
  },
};

const Talent = {
  getTalent: (talentId: number) => {
    const apiPath = `/talent/getById?talentId=${talentId}`;
    return fetchApi(apiPath, GET_METHOD);
  },
};

// 获取实验室文化
const Culture = {
  getStudentYear: () => {
    const apiPath = 'culture/getStudentYearList';
    return fetchApi(apiPath, GET_METHOD);
  },
  getStudentByYear: (year: number) => {
    const apiPath = `culture/getAllStudentByYear?year=${year}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getAllStudents: () => {
    const apiPath = 'culture/getAllStudents';
    return fetchApi(apiPath, GET_METHOD);
  },
  getActivity: (offset: number) => {
    const apiPath = `culture/getActivitySimpleList?offset=${offset}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getActivityCount: () => {
    const apiPath = 'culture/getActivityCount';
    return fetchApi(apiPath, GET_METHOD);
  },
  getActivityDetail: (id: string) => {
    const apiPath = `culture/getActivityById?activityId=${id}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getPhoto: (offset: number) => {
    const apiPath = `culture/getPhotoByOffset?offset=${offset}`;
    return fetchApi(apiPath, GET_METHOD);
  },
  getPhotoCount: () => {
    const apiPath = 'culture/getPhotoCount';
    return fetchApi(apiPath, GET_METHOD);
  },
};

export {
  Home, Lab, Direction, News, Project, Download, Achievement, Culture, Talent,
};
