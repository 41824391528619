import React, { useEffect, useState } from 'react';
import { Achievement as AchievementApi } from '../../api/common';
import Pagination from '../Public/Pagination';

interface Paper {
  paperId:number,
  paperContent:string,
  paperNotes:string,
  paperDate :string,
  paperTypical: number,
}

function PaperView() {
  const [paperData, setPaperData] = useState<Paper[]>([]);
  const [yearData, setYearData] = useState<number[]>([]);
  const [paperCount, setPaperCount] = useState(0);
  const typicalData = [{ typeId: 0, typeName: '全部论文' }, { typeId: 1, typeName: '代表论文' }];
  const [selectedType, setSelectedType] = useState(0);
  const [selectedYear, setSelectedYear] = useState(0);

  useEffect(() => {
    const fetchPaper = async () => {
      setPaperData(await AchievementApi.getPaper(0));
    };
    fetchPaper();

    const fetchPaperCount = async () => {
      setPaperCount(await AchievementApi.getPaperCount());
    };
    fetchPaperCount();

    const fetchYear = async () => {
      setYearData(await AchievementApi.getPaperYear());
    };
    fetchYear();
  }, []);

  const handleChangeType = async (type: any) => {
    setSelectedType(type.typeId);
    if (type.typeName === '全部论文') {
      setPaperData(await AchievementApi.getPaper(0));
      setPaperCount(await AchievementApi.getPaperCount());
    } else {
      setPaperData(await AchievementApi.getPaperByType(0));
      setPaperCount(await AchievementApi.getPaperCountByType());
    }
  };

  const handleChangeYear = async (year:number) => {
    setSelectedYear(year);
    setSelectedType(0);
    setPaperData(await AchievementApi.getPaperByYear({ offset: 0, year }));
    setPaperCount(await AchievementApi.getPaperCountByYear(year));
  };

  const handleChangePage = async (page:number) => {
    if (selectedType === 0) {
      if (selectedYear !== 0) {
        // 年分页
        // eslint-disable-next-line max-len
        setPaperData(await AchievementApi.getPaperByYear({ offset: (page - 1) * 10, year: selectedYear }));
        setPaperCount(await AchievementApi.getPaperCountByYear(selectedYear));
      } else {
        // 全部类型分页
        setPaperData(await AchievementApi.getPaper((page - 1) * 10));
        setPaperCount(await AchievementApi.getPaperCount());
      }
    } else {
      // 类型分页
      setPaperData(await AchievementApi.getPaperByType((page - 1) * 10));
      setPaperCount(await AchievementApi.getPaperCountByType());
    }
  };

  const paperList = (
    <div>
      <div className="list-l-wz">
        {
          paperData.map((item) => (
            // eslint-disable-next-line
            <a key={item.paperId}>
              <div className="list-l-bg" />
              <h3>
                {item.paperTypical
                  ? <span style={{ color: '#004EB4' }}> [代表论文] </span> : null}
                {item.paperContent}
                {`(${item.paperNotes})`}
              </h3>
              {/* eslint-disable-next-line max-len */}
              <p>{(typicalData.filter((_item) => _item.typeId === item.paperTypical))[0].typeName}</p>
              <div className="list-l-time">
                <span>
                  {item.paperDate.substr(0, 4)}
                </span>
              </div>
            </a>
          ))
        }
      </div>
    </div>
  );

  return (
    <div>
      <div className="news-filter">
        <div className="filters">
          <div className="filter-title">Topic</div>
          {
            typicalData.map((item) => (
              // eslint-disable-next-line
              <div
                key={item.typeId}
                className={selectedType === item.typeId ? 'filter-ins active' : 'filter-ins'}
                onClick={() => {
                  handleChangeType(item);
                }}
              >
                {item.typeName}
              </div>
            ))
          }
        </div>
        <div className="filters">
          <div className="filter-title">By Year</div>
          {
            yearData.map((item) => (
              // eslint-disable-next-line
              <div
                key={item}
                className={selectedYear === item ? 'filter-ins active' : 'filter-ins'}
                onClick={() => {
                  handleChangeYear(item);
                }}
              >
                {item}
              </div>
            ))
          }
        </div>
      </div>
      {
        paperList
      }
      <Pagination count={paperCount} onChangePage={handleChangePage} />
    </div>
  );
}

export default PaperView;
