const API_ROOT = 'http://47.92.170.198:33333';

interface req {
  headers?: any,
  body?: any,
  method?: string
}

const fetchApi = async (apiPath: string, request: req = {}) => {
  const fullUrl = `${API_ROOT}/${apiPath}`;
  const { headers, body, method } = request;
  const customRequest: req = {};

  if (method) {
    customRequest.method = method.toUpperCase();
  }
  if (body) {
    customRequest.body = body;
  }
  if (headers) {
    const { contentType, auth } = headers;
    customRequest.headers = {};

    if (contentType) {
      customRequest.headers['Content-Type'] = contentType;
    }
    if (auth) {
      customRequest.headers.Authentication = auth;
    }
  }
  return new Promise<any>((resolve, reject) => {
    fetch(fullUrl, customRequest).then((resp) => {
      if (resp.ok) {
        resolve(resp.json());
      }
    }).catch((error) => reject(error));
  });
};

export default fetchApi;
