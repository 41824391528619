// import React, { useState, useEffect } from 'react';
import React from 'react';
import {
  Carousel, Card, ListGroup, Badge,
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../styles/home.scss';
// import {
//   Home as HomeApi,
//   Culture as CultureApi
// } from '../api/common';

import QRCode from '../assets/wechat/tanklab_official.jpg';

/*
interface Researcher {
  studentAcademicStart: string,
  studentGender: string,
  studentGraduationTime: string,
  studentHomepage: string,
  studentId: number,
  studentImg: string,
  studentInSchool: number, // 离校0 在校1
  studentJob: string,
  studentMotto: string,
  studentName: string,
  studentResearch: string,
  studentTutor: string,
  studentType: number// 硕士0 博士1
}
*/

// interface newsItem {
//   textId: number,
//   textTitle: string,
//   newDate: string,
// }

function Home() {
  const carouselImg = [
    'https://tanklab-bucket.oss-cn-beijing.aliyuncs.com/img/home-scroll-0.jpg',
    'https://tanklab-bucket.oss-cn-beijing.aliyuncs.com/img/home-scroll-1.jpg',
    'https://tanklab-bucket.oss-cn-beijing.aliyuncs.com/img/home-scroll-2.jpg',
    'https://tanklab-bucket.oss-cn-beijing.aliyuncs.com/img/home-scroll-3.jpg',
  ];

  // const [newsData, setData] = useState([]);
  // const [AllStudentData, setAllStudentData] = useState<Researcher[]>([]);

  // useEffect(() => {
  //   // 获取新闻列表
  //   const fetchData = async () => {
  //     setData(await HomeApi.getNewsList(5));
  //   };
  //   fetchData();
  //   const fetchAllStudent = async () => {
  //     setAllStudentData(await CultureApi.getAllStudents());
  //   };
  //   fetchAllStudent();
  // }, []);
  // const news = () => {
  //   const newsList: JSX.Element[] = [];
  //   if (newsData) {
  //     newsData.forEach((data: newsItem) => {
  //       newsList.push(
  //         <ListGroup.Item className="home-news-item" key={data.textId}>
  //           <a href={`/Detail?type=news&id=${data.textId}`}>{data.textTitle}</a>
  //           <p className="home-news-date">{data.newDate}</p>
  //         </ListGroup.Item>,
  //       );
  //     });
  //   }
  //   return newsList;
  // };

  const carousel = () => {
    const carouselList: JSX.Element[] = [];
    carouselImg.forEach((e: string) => {
      carouselList.push(
        <Carousel.Item key={e}>
          <img
            className="d-block w-100"
            src={e}
            alt="轮播图"
          />
        </Carousel.Item>,
      );
    });
    return carouselList;
  };

  // const researcherList = (
  //   <ul className="lecturelist clearfix">
  //     {AllStudentData.map((item) => (
  //       <li key={item.studentId} className="yjry_list">
  //         <a href={item.studentHomepage}>
  //           <img className="home-researcher-img" src={item.studentImg} alt={item.studentName} />
  //           <h3>{item.studentName}</h3>
  //           <span>{`${item.studentResearch}`}</span>
  //         </a>
  //       </li>
  //     ))}
  //   </ul>
  // );

  const tutors = [
    {
      name: '李克秋教授',
      email: 'keqiu@tju.edu.cn',
      page: 'http://cic.tju.edu.cn/faculty/likeqiu/index.html',
    }, {
      name: '王建荣教授',
      email: 'wjr@tju.edu.cn',
      page: 'http://cic.tju.edu.cn/faculty/wjr/index.html',
    }, {
      name: '喻梅教授',
      email: 'yueyouyueyou@gmail.com',
      page: 'http://cic.tju.edu.cn/faculty/yumei/index.html',
    }, {
      name: '邱铁教授',
      email: 'qiutie@ieee.org',
      page: 'http://www.qtie.net',
    }, {
      name: '郭得科教授（合作）',
      email: 'guodeke@gmail.com',
      page: 'http://www.greenorbs.org/people/liu/guodeke/index.html',
    }, {
      name: '曲雯毓教授',
      email: 'wenyu.qu@tju.edu.cn',
      page: 'http://www.tjutanklab.com/wyqu/',
    }, {
      name: '周晓波教授',
      email: 'xiaobo.zhou@tju.edu.cn',
      page: 'http://cic.tju.edu.cn/faculty/xbzhou/index.html',
    }, {
      name: '刘秀龙教授',
      email: 'xiulongliudut@gmail.com',
      page: 'http://cic.tju.edu.cn/faculty/liuxiulong/index.html',
    }, {
      name: '赵来平教授',
      email: 'laiping@tju.edu.cn',
      page: 'http://clouduv.cn/',
    }, {
      name: '张朝昆副教授',
      email: 'zhangchaokun@tju.edu.cn',
      page: 'http://cic.tju.edu.cn/faculty/zhangchaokun/index.html',
    }, {
      name: '刘春凤副教授',
      email: 'cfliu@tju.edu.cn',
      page: 'http://cic.tju.edu.cn/faculty/cfliu/index.html',
    }, {
      name: '李文信副教授',
      email: 'toliwenxin@gmail.com',
      page: 'http://cic.tju.edu.cn/faculty/liwenxin/index.html',
    }, {
      name: '胡一涛副研究员',
      email: 'yitao.cs@gmail.com',
      page: 'https://flashserve.org/',
    }, {
      name: '石拓副研究员',
      email: 'shituo@tju.edu.cn',
      page: 'http://cic.tju.edu.cn/faculty/shituo/index.html',
    }, {
      name: '佟鑫宇副研究员',
      email: 'xytong@tju.edu.cn',
      page: 'http://cic.tju.edu.cn/faculty/tongxinyu/index.html',
    },
  ];

  const tutorList = () => {
    const list: JSX.Element[] = [];
    tutors.forEach((item) => {
      list.push(
        <ListGroup.Item className="home-tutor-item-wrapper">
          <div className="home-tutor-name">{item.name}</div>
          <div className="home-tutor-email">
            <Badge>邮箱</Badge>
            <span className="ml-5">{item.email}</span>
          </div>
          <div className="home-tutor-link">
            <Badge bg="info">个人主页</Badge>
            <a
              href={item.page}
              target="_blank"
              rel="noreferrer"
              className="ml-5"
            >
              {item.page}
            </a>
          </div>
        </ListGroup.Item>,
      );
    });
    return list;
  };

  return (
    <div>
      <Carousel fade controls={false} interval={2000}>
        {carousel()}
      </Carousel>
      <div className="cardgroup">
        <Card className="home-brief col-md-10">
          <Card.Body>
            <Card.Title className="home-brief-title">简介</Card.Title>
            <Card.Text className="home-brief-text">
              &nbsp; &nbsp; &nbsp; &nbsp;网络与云计算导师团依托于天津市先进网络技术与应用重点实验室
              其导师成员为李克秋、喻梅、王建荣、邱铁、曲雯毓、郭得科、刘秀龙、周晓波、赵来平、张朝昆、刘春凤、佟鑫宇、胡一涛、石拓。
              研究方向主要为：
              <b>人工智能与区块链技术、未来网络与下一代互联网、智慧城市与物联网技术、数据中心与云计算系统、智能网联汽车以及网络安全</b>
              等方面。
            </Card.Text>
            <Card.Text className="home-brief-text">
              &nbsp; &nbsp; &nbsp; &nbsp;实验室师资强劲，拥有
              <b>
                IEEE Fellow 1人，国家杰出青年基金获得者1人，
                国家“万人计划”科技创新领军人才2人，国家优秀青年基金获得者1人、国家万人计划青年拔尖人才1人，
                教育部青年长江学者1人，海外优青2人，教育部新世纪人才1人、天津市杰出青年1人，CCF优博2人
              </b>
              。近三年来发表论文百余篇，其中发表在中国计算机学会（CCF）规定A类会议和期刊或者中科院一区40余篇。
              近三年累计科研经费到款6000余万元，在研科研项目50余项，其中包括数十项国家级重点项目。
            </Card.Text>
            <Card.Text className="home-brief-text">
              &nbsp; &nbsp; &nbsp; &nbsp;近年来，实验室研究工作再上新台阶。实验室成功申请并获批
              <b>
                2019年度国家重点研发计划
                “物联网与智慧城市关键技术及示范”重点专项——“面向智慧生活的安全可信智能物联平台与融合服务”
              </b>
              。其研究成果有望形成覆盖智慧生活产业链上下游，集技术、产品、系统为一体的整体解决方案及应用示范的新模式，
              促进人工智能产业链在智慧生活领域的纵向整合，打造“硬件+软件+应用+服务”一体化生态圈，为践行“数字中国”战略的创新发展提供成功范例。
            </Card.Text>
            <Card.Text className="home-brief-text">
              &nbsp; &nbsp; &nbsp; &nbsp;2022年，实验室成功申请并获批
              <b>
                国家重点研发计划“区块链”重点专项“高性能自适应跨链互通关键技术及
                试验验证（共性关键技术类）”项目
              </b>
              。在科学成果方面，本项目将突破跨链架构、交互机制、监管治理、应用部署方面的关键技术，实现高性能、强监管、自适应的跨链互通解决方案。
              在社会效益方面，跨链技术将打破社会信任孤岛，促进产业转型升级，为发展提质增效，助力“十四五”规划实施。
              在经济效益方面，团队预计在未来进一步扩大业务范围，打通上下游，降低损耗。
              在生态效益方面，本项目旨在形成完整的区块链跨链产业链条，显著降低社会交易成本，大幅提升异构链间协同性，下好跨链生态“一盘棋”。
            </Card.Text>
            <Card.Text className="home-brief-text">
              &nbsp; &nbsp; &nbsp; &nbsp;2022年3月，由实验室李克秋教授主持完成的
              <b>“软件定义的云计算资源管理平台”</b>
              荣获
              <b>2021年度天津市科学技术特等奖</b>
              。该项目从资源管理系统结构、计算资源弹性分配和网络带宽资源调度三方面展开研究，突破了面向大规模云数据中心的资源管理关键技术，
              并在紫光云真实场景下开展应用实践，形成了高效云资源管理平台软件。该软件具有高吞吐、高利用、国产化生态兼容等特点，
              提高了大规模云数据中心下资源利用效率，支撑了人工智能、大数据、在线服务等众多云应用的稳定高效运行。
            </Card.Text>
            <Card.Text className="home-brief-text">
              &nbsp; &nbsp; &nbsp; &nbsp;在开展高水平科研工作的同时，实验室先后与青岛海信集团、安全部、中国汽车研究院、天津电信、神州数码、紫光云、华为等
              政企部门联合研发智能物联网平台、智慧城市网络和边缘智慧平台，实现了高质量的人才培养，以及相关科研成果的转化及产学研融合。
            </Card.Text>
            <Card.Text className="home-brief-text">
              &nbsp; &nbsp; &nbsp; &nbsp;作为
              <b>天津大学金牌活力实验室</b>
              ，实验室提供优越的软硬件条件、丰厚的奖助学金及待遇和一流的科研平台和环境。
              实验室注重情商培养，定期组织团建和体育赛事。实验室与国际知名团队长期保持合作，支持学生进行联合培养和短期交换。
              实验室鼓励学生参会交流，拓展视野。毕业生就业几乎前往大厂或去985/211等科研单位任教。
            </Card.Text>
            {/* <a className="more-button" href="/overview/info">更多</a> */}
          </Card.Body>
        </Card>
        {/* <Card className="home-news col-md-5">
          <Card.Body>
            <Card.Title className="home-news-title">新闻动态</Card.Title>
            <ListGroup variant="flush">
              {news()}
            </ListGroup>
            <a className="more-button" href="/news">更多</a>
          </Card.Body>
        </Card> */}
      </div>
      {/* <div className="cardgroup">
        <Card className="home-researcher col-md-10">
          <Card.Body>
            <Card.Title className="home-researcher-title">研究人员</Card.Title>
            {researcherList}
          </Card.Body>
        </Card>
      </div> */}
      <div className="cardgroup">
        <Card className="home-tutor col-md-10">
          <Card.Body>
            <Card.Title className="home-tutor-title">导师信息</Card.Title>
            <ListGroup>
              {tutorList()}
            </ListGroup>
          </Card.Body>
        </Card>
      </div>
      <div className="cardgroup">
        <Card className="home-more col-md-10">
          <Card.Body>
            <Card.Title className="home-more-title">更多</Card.Title>
            <Card.Text className="home-more-text">更多导师及实验室相关信息，请关注实验室微信公众号：</Card.Text>
            <img src={QRCode} alt="wechat_official_account" height={200} />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default Home;
