import React, { useEffect, useState } from 'react';
import Pagination from '../Public/Pagination';
import { News as NewsApi } from '../../api/common';

interface News {
  textId: number,
  textTitle: string,
  textDesc: string,
  textContent: string,
  textDate: string,
  textImg: string,
  textType: number,
  xtReference: number,
  newId: number,
  newDate: string,
  newTags: string,
  newTopping: number,
}

interface Tag {
  tagId: number,
  tagName: string,
  tagReference: number,
}

function NewsView() {
  const [newsData, setNewsData] = useState<News[]>([]);
  const [topicData, setTopicData] = useState<Tag[]>([]);
  const [yearData, setYearData] = useState<number[]>([]);
  const [selectedTag, setSelectedTag] = useState<number>(-1);
  const [selectedYear, setSelectedYear] = useState<number>(0);
  const allType = { tagId: -1, tagName: '全部类型', tagReference: 3 };
  const [newsCount, setNewsCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setNewsData(await NewsApi.getNews(0));
    };
    fetchData();

    const fetchNewsCount = async () => {
      setNewsCount(await NewsApi.getNewsCount());
    };
    fetchNewsCount();

    const fetchTopic = async () => {
      setTopicData(await NewsApi.getTopic());
    };
    fetchTopic();

    const fetchYear = async () => {
      setYearData(await NewsApi.getYear());
    };
    fetchYear();
  }, []);

  const handleChangeTag = async (tag: Tag) => {
    setSelectedTag(tag.tagId);
    if (tag.tagName === '全部类型') {
      setNewsData(await NewsApi.getNews(0));
      setNewsCount(await NewsApi.getNewsCount());
    } else {
      setNewsData(await NewsApi.getNewsByTag({ offset: 0, tagId: tag.tagId }));
      setNewsCount(await NewsApi.getNewsCountByTag(tag.tagId));
    }
  };

  const handleChangeYear = async (year: number) => {
    setSelectedYear(year);
    setSelectedTag(-1);
    setNewsData(await NewsApi.getNewsByYear({ offset: 0, year }));
    setNewsCount(await NewsApi.getNewsCountByYear(year));
  };

  const handleChangePage = async (page: number) => {
    if (selectedTag === -1) {
      if (selectedYear !== 0) {
      // 年分页
        setNewsData(await NewsApi.getNewsByYear({ offset: (page - 1) * 10, year: selectedYear }));
        setNewsCount(await NewsApi.getNewsCountByYear(selectedYear));
      } else {
      // 全部类型分页
        setNewsData(await NewsApi.getNews((page - 1) * 10));
        setNewsCount(await NewsApi.getNewsCount());
      }
    } else {
      // 类型分页
      setNewsData(await NewsApi.getNewsByTag({ offset: (page - 1) * 10, tagId: selectedTag }));
      setNewsCount(await NewsApi.getNewsCountByTag(selectedTag));
    }
  };

  const newsList = (
    <div className="pic-list">
      {
        newsData.map((item) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a href={`/Detail?type=news&id=${item.newId}`} key={item.newId}>
            <img src={item.textImg} alt={item.textTitle} />
            <h3 className="over-hidden">{item.textTitle}</h3>
            <p className="over-hidden__two-line">{item.textDesc}</p>
            <div className="list-l-time">
              <span>{item.newDate}</span>
            </div>
          </a>
        ))
      }
    </div>
  );

  return (
    <div>
      <div className="news-filter">
        <div className="filters">
          <div className="filter-title">Topic</div>
          {/* eslint-disable-next-line */}
          <div
            key={allType.tagId}
            className={selectedTag === allType.tagId ? 'filter-ins active' : 'filter-ins'}
            onClick={() => {
              handleChangeTag(allType);
            }}
          >
            {allType.tagName}
          </div>
          {
            topicData.map((item) => (
              // eslint-disable-next-line
              <div
                key={item.tagId}
                className={selectedTag === item.tagId ? 'filter-ins active' : 'filter-ins'}
                onClick={() => {
                  handleChangeTag(item);
                }}
              >
                {item.tagName}
              </div>
            ))
          }
        </div>
        <div className="filters">
          <div className="filter-title">By Year</div>
          {
            yearData.map((item) => (
              // eslint-disable-next-line
              <div
                key={item}
                className={selectedYear === item ? 'filter-ins active' : 'filter-ins'}
                onClick={() => {
                  handleChangeYear(item);
                }}
              >
                {item}
              </div>
            ))
          }
        </div>
      </div>
      {
        newsList
      }
      <Pagination count={newsCount} onChangePage={handleChangePage} />
    </div>
  );
}

export default NewsView;
